@import 'Styles/includes';

.Pagination {
    $root: &;

    &__List {
        display: flex;
        justify-content: center;
        margin-left: -2px;
    }

    &__Item {
        display: block;
        margin-left: 2px;
        transition: opacity $transition;

        &--Disabled {
            opacity: 0;
        }
    }

    &__Link {
        @include textstyle(h4);

        position: relative;
        display: block;
        height: 44px;
        padding: 0 7px;
        color: black;
        line-height: 44px !important;
        text-align: center;
        background: transparent;
        transition: background $transition, color $transition;
        cursor: pointer;
        outline: none;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 8px;
            left: 7px;
            right: 7px;
            height: 2px;
            background: black;
            opacity: 0;
            transition: opacity $transition;
        }

        #{$root}__Item:not(#{$root}__Item--Active) &:hover {
            color: $purple;
            background: $purple-20;

            svg {
                fill: $purple;
            }
        }

        #{$root}__Item--Active & {
            cursor: default;

            &:hover {
                color: black;
            }

            &::after {
                opacity: 1;
            }
        }

        svg {
            width: 7px;
            height: 14px;
            fill: black;
            position: relative;
            transition: fill $transition;
        }

        #{$root}__Item--Prev &,
        #{$root}__Item--Next & {
            width: 44px;
        }

        #{$root}__Item--Prev & svg {
            left: -1px;
        }

        #{$root}__Item--Next & svg {
            right: -1px;
        }
    }

    &__Break {
        @include textstyle(h4);

        display: block;
        min-width: 20px;
        height: 44px;
        line-height: 44px !important;
        text-align: center;
        background: transparent;

        &:hover {
            color: black;
        }
    }
}
