@use 'sass:math';

@import 'Styles/includes';

.Chart {
    $root: &;

    &--Wide,
    &--Full {
        margin-left: 16px !important;
        margin-right: 16px !important;
    }

    &--Longread#{$root}--Full {
        @include media(ML) {
            margin-right: 0 !important;
        }
    }

    &__Tabbed {
        margin: 30px 0;

        @include media(M) {
            margin: 60px 0;
        }
    }

    &__TabList {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &__Tab {
        @include textstyle(body-small);

        position: relative;
        flex: 0 0 auto;
        padding: 8px 10px;
        color: $grey;
        text-align: center;
        border: none;
        border-radius: 0;
        box-shadow: none;
        background: none;
        transition: color $transition;
        outline: none;
        cursor: pointer;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 1px;
            background: $grey-20;
            transition: background $transition;
        }

        @include media(M) {
            padding: 12px 20px;
        }

        &:not(#{$root}__Tab--Active) {
            &:hover,
            &:focus {
                color: $purple;
                text-decoration: none;

                &::after {
                    background: $purple;
                }
            }
        }

        &--Active {
            color: black;
            cursor: default;

            &::after {
                background: black;
            }
        }

        @media print {
            display: none;
        }
    }

    &__Content {
        position: relative;
        background: white;
        width: 100%;
    }

    &__DiagramPlaceholder {
        position: relative;

        &::before {
            content: '';
            display: block;
            padding-top: #{percentage(math.div(9, 16))};
        }
    }
}
