@import 'Styles/includes';

.ToTop {
    $root: &;

    @include button(secondary small);

    position: absolute;
    bottom: 32px;
    right: 12px;
    padding-left: 7px;
    transition: transform $transition;

    @include media(SL) {
        bottom: 12px;
    }

    @include media(ML) {
        position: relative !important;
        bottom: auto;
        right: auto;
    }

    &::after {
        right: 11px;
        transform: rotate(-90deg) !important;
    }

    span {
        position: relative;
        top: 1px;
        display: inline-block;
        height: 18px;
        white-space: nowrap;
        max-width: 0;
        opacity: 0;
        overflow: hidden;
        transition: all $transition;
    }

    &--XOffset {
        @include media(ML) {
            position: absolute !important;
            bottom: 0;
            top: auto;
            right: -65px;
        }
    }

    &--Open span {
        padding-left: 6px;
        padding-right: 4px;
        max-width: 150px;
        opacity: 1;
    }

    &:hover span {
        @include media(ML) {
            padding-left: 6px;
            padding-right: 4px;
            max-width: 150px;
            opacity: 1;
        }
    }
}
