@use 'sass:math';

@import 'Styles/includes';

.Search {
    $root: &;

    margin: 40px 0;

    @include media(M) {
        margin: 60px 0;
    }

    @include media(L) {
        margin: 80px 0;
    }

    &__Wrap {
        @include wrap;
    }

    &__Header {
        display: flex;
        flex-wrap: wrap;
        margin-left: -$gutter;

        @include media(M) {
            margin-left: -$gutterM;
        }

        #{$root}--Filter & {
            margin-bottom: 40px;

            @include media(M) {
                margin-bottom: 60px;
            }

            @include media(L) {
                margin-bottom: 80px;
            }
        }
    }

    &__Label {
        @include textstyle(label);

        display: block;
        margin-bottom: 8px;
        color: $grey;

        @include media(M) {
            margin: -14px 0 16px;
        }
    }

    &__Title {
        @include textstyle(h2);

        margin: 0 auto 24px $gutter;

        @include media(M) {
            margin: 0 auto 40px $gutterM;
        }

        #{$root}--Filter & {
            max-width: 296px;
        }

        #{$root}--Global & {
            @include media(L) {
                margin-bottom: 0;
                font-size: 3.4rem;
            }
        }
    }

    &__Filter {
        width: 100%;
        margin-left: $gutter;

        @include media(M) {
            margin-left: $gutterM;
        }

        @include media(L) {
            width: calc(#{percentage(math.div(2, 3))} - #{$gutterM});
        }
    }

    &__Tabs {
        position: relative;
        margin-bottom: 9px;

        @include media(ML) {
            margin-bottom: 24px;
        }
    }

    &__Secondary {
        margin-top: 40px;

        @include media(M) {
            margin-top: 60px;
        }

        @include media(L) {
            margin-top: 80px;
        }
    }
}
