@import 'Styles/includes';

.ParallaxShape {
    $root: &;

    display: block;
    position: absolute;
    bottom: 100px;
    width: 500px;
    height: 600px;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    z-index: -2;

    @include media(M) {
        width: 610px;
        height: 750px;
    }

    /* Custom modifiers */
    &--ArticleList {
        bottom: 40px;
    }

    &--FormModule {
        transform: translateY(-140px);
    }

    &--Large {
        width: 610px;
        height: 750px;
        bottom: -50px;

        @include media(M) {
            width: 870px;
            height: 1140px;
        }
    }

    &--Left {
        right: 75%;

        &#{$root}--Large {
            right: 60%;
        }
    }

    &--Right {
        left: 75%;

        &#{$root}--Large {
            left: 60%;
        }
    }

    &--Middle {
        width: 400px;
        height: 460px;
        right: 0;
        left: 0;
        margin: auto;
        z-index: -1;

        @include media(M) {
            width: 510px;
            height: 586px;
        }
    }

    &__Inner {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;

        #{$root}--Left & {
            background-position: bottom left;
            background-image: url('#{$assetsPath}img/shapeLeft--salmon30.svg');

            :global(.Theme--Arkitekturgalan) & {
                background-image: url('#{$assetsPath}img/shapeLeft--arkitekturgalan-orange.svg');
            }
        }

        #{$root}--Right & {
            background-position: bottom right;
            background-image: url('#{$assetsPath}img/shapeRight--salmon30.svg');

            :global(.Theme--Arkitekturgalan) & {
                background-image: url('#{$assetsPath}img/shapeRight--arkitekturgalan-orange.svg');
            }
        }

        #{$root}--Middle & {
            background-position: center top;
            background-image: url('#{$assetsPath}img/shapeMiddle--salmon30.svg');

            :global(.Theme--Arkitekturgalan) & {
                display: none;
            }
        }
    }
}
