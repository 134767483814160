@import 'Styles/includes';

.DescriptionList {
    $root: &;

    &__Item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 12px;

        @include media(M) {
            margin-bottom: 20px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__Label {
        @include textstyle(h4);

        flex: 0 0 auto;
        width: 100%;
        padding-right: 16px;
        color: $heading-color;

        @include media(SL) {
            width: 35%;
        }

        @include media(M) {
            width: 100%;
        }

        @include media(L) {
            width: 35%;
        }
    }

    &__Value {
        @include textstyle(body-regular);

        flex: 1 1 auto;
        width: 100%;

        @include media(SL) {
            width: 65%;
        }

        @include media(M) {
            width: 100%;
        }

        @include media(L) {
            width: 65%;
        }

        a {
            word-break: break-word;
            text-decoration: underline;
            transition: color $transition;

            @include href-external {
                &::after {
                    content: '';
                    margin-left: 9px;
                    width: 13px;
                    height: 13px;
                    display: inline-block;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-image: url('#{$assetsPath}img/external--grey.svg');
                    transition: background-image $transition;
                }
            }

            &:hover {
                color: $purple;

                &::after {
                    background-image: url('#{$assetsPath}img/external--purple.svg') !important;
                }
            }
        }
    }
}
