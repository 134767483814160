@import 'Styles/includes';

.Table {
    $root: &;

    &__Title {
        @include textstyle(h3);

        margin-bottom: 20px !important;

        @include media(M) {
            margin-bottom: 40px !important;
        }
    }

    &__Content {
        overflow-x: auto;
    }

    &__Table {
        border: 0;
        font-size: 14px !important;
        border-bottom: 2px solid $accent-color;
        margin-bottom: 0 !important;
        background: white;

        @include media(L) {
            font-size: 16px !important;
        }

        #{$root}--Purple & {
            border-color: $primary-color;
        }

        #{$root}--Wide &,
        #{$root}--Full & {
            width: 100%;
        }
    }

    &__Row {
        &--Header {
            background-color: $accent-color;
            color: white;
            font-weight: $bold;

            #{$root}--Purple & {
                background-color: $primary-color;
            }
        }

        &--Regular:nth-child(even) {
            background-color: $salmon-30;

            #{$root}--Purple & {
                background-color: $purple-20;
            }
        }
    }

    &__Cell {
        display: table-cell;
        padding: 18px 0 18px 20px;
        white-space: nowrap;
        vertical-align: top;
        border: 0;

        &:last-child {
            padding-right: 20px;
            margin-left: auto;
        }

        @include media(M) {
            padding-left: 24px;

            &:last-child {
                padding-right: 24px;
            }
        }

        &--Header {
            padding-top: 16px;
            padding-bottom: 16px;
        }

        p,
        a {
            font-size: 14px !important;

            @include media(L) {
                font-size: 16px !important;
            }
        }

        p {
            margin-bottom: 0 !important;
            line-height: 1.8 !important;
        }
    }
}
