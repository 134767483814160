/*
    Always include a span in loader
    e.g. Checkbox markup
    <div class="loader loader--large">
        <span></span>
    </div>
*/

%loader {
    &--small {
        @include loader(small);

        :global(.Theme--Arkitekturgalan) & {
            @include loader(small, 1, $arkitekturgalan-primary);
        }
    }

    &--large {
        @include loader(large, 6);

        :global(.Theme--Arkitekturgalan) & {
            @include loader(large, 6, $arkitekturgalan-primary);
        }
    }
}
