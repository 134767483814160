@import 'Styles/includes';

.Gallery {
    $root: &;

    &__Content {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 0 -8px !important;
        list-style: none;

        @include media(SL) {
            margin: 0 0 0 -16px !important;
        }
    }

    &__Item {
        width: calc(50% - 8px);
        margin: 0 0 8px 8px !important;
        padding: 0 !important;
        position: relative;

        @include media(SL) {
            width: calc(50% - 16px);
            margin: 0 0 16px 16px !important;
        }

        @include media(M) {
            width: calc(25% - 16px);
            margin: 0 0 16px 16px !important;
        }

        &:first-child {
            width: calc(100% - 8px);

            @include media(SL) {
                width: calc(100% - 16px);
            }
        }

        &--ShowMore {
            background-color: $purple-20;

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: 68.6%;
            }

            :global(.Theme--Arkitekturgalan) & {
                background: $arkitekturgalan-secondary;
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            left: 0 !important;
            width: auto !important;
            height: auto !important;
            background: transparent !important;
            box-shadow: 0 0 32px 0 rgba(black, .12);
            opacity: 0;
            transition: opacity $transition;
        }

        &:hover {
            &::before {
                opacity: 1;
            }
        }
    }

    &__ShowMore {
        @include textstyle(label);
        color: $purple;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-flow: column;
        justify-content: center;
        text-align: center;
        cursor: pointer;

        :global(.Theme--Arkitekturgalan) & {
            color: black;
        }
    }

    &__NotShowing {
        @include textstyle(h3);
        text-align: center;
        line-height: 1 !important;
        display: block;
        margin-bottom: 7px;
        margin-left: -3px;
    }

    &__Icon {
        display: block;
        margin-bottom: 10px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &__Caption {
        @include textstyle(helptext);
        display: block !important;
        text-align: left;
        margin-bottom: 16px;
        margin-top: 0 !important;

        @include media(SL) {
            margin-top: -8px !important;
        }
    }

    &__Modal {
        position: relative;
        z-index: 1;
    }
}
