@import 'Styles/includes';

.ShareLinks {
    $root: &;

    display: flex;

    @media print {
        display: none;
    }

    &__Label {
        flex: 0 0 auto;
        display: block;
        margin-right: 21px;
    }

    &__List {
        display: flex;
        margin: -10px;
    }

    &__Item {
        flex: 0 0 auto;
    }

    &__Link {
        width: 38px;
        height: 38px;
        padding: 10px;
        display: block;

        svg {
            display: inline-block;
            width: 18px;
            height: 18px;
            fill: $purple;
            border-radius: 2px;
            transition: fill $transition;
        }

        &:hover {
            svg {
                fill: $purple-60;
            }
        }
    }
}
