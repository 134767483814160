@import 'Styles/includes';

.SearchField {
    $root: &;

    @extend %form__field;
    @extend %form__field--search;

    // Same style in SearchForm
    &--RekAI {
        :global {
            .rekai-autocomplete {
                &.rekai-styled {
                    .rekai-dropdown-menu {
                        border: none;
                        border-radius: 0;
                        background: $grey-10;
                        box-shadow: 0 4px 4px 0 rgba(black, .25);

                        .rekai-suggestion {
                            @include textstyle(h5);

                            padding: 7px 14px;
                            border-top: 1px solid white;

                            &:first-child {
                                border: none;
                            }

                            &.rekai-cursor,
                            &:hover {
                                background-color: $purple-20;
                            }
                        }
                    }
                }
            }
        }
    }

    &__Input {
        @extend %form__input;
        @extend %form__input--search;

        margin: 0;

        #{$root}:not(#{$root}--Global) & {
            @extend %form__input--label-inside;
        }
    }

    &__Label {
        @extend %form__label;
        @extend %form__label--search;
        @extend %form__label--inside;

        #{$root}__Input:focus + &,
        #{$root}--HasValue & {
            @extend %form__label--inside-focus;
        }

        #{$root}--Global & {
            @extend %sr-only;
        }
    }
}
