@import 'Styles/includes';

.Comments {
    $root: &;

    &__Disqus {
        a {
            color: $purple;
        }

        iframe {
            margin-top: 20px;

            @include media(M) {
                margin-top: 46px;
            }
        }
    }
}
