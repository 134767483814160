@import 'Styles/includes';

.CompetitionEntry {
    $root: &;

    &__InfoCompetition {
        margin-bottom: $gutter;

        @include media(M) {
            margin-bottom: $gutterM;
        }
    }

    &__LinkList {
        margin-bottom: 24px;
    }

    &__ShareLinks {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;

        @include media(M) {
            margin-top: 0;
        }
    }
}
