@import 'Styles/includes';

.SearchSort {
    $root: &;

    @extend %form__field;
    @extend %form__field--select;

    flex: 0 0 auto;

    &::after {
        bottom: 17px;

        @include media(M) {
            bottom: 21px;
        }
    }

    &__Select {
        @extend %form__input;
        @extend %form__input--select;

        padding-top: 8px;
        padding-bottom: 8px;

        @include media(M) {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }
}
