@import 'Styles/includes';

.SearchTabs {
    $root: &;

    position: relative;
    width: calc(100% + 60px);
    max-width: 100vw;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 60px;
        height: 100%;
        background-image: linear-gradient(to right, rgba($grey-10, 0) 8%, $grey-10);
    }

    @include media(XL) {
        left: auto;
        width: auto;

        &::after {
            display: none;
        }
    }

    @include media(L) {
        margin-bottom: 0;
    }

    &__Container {
        position: relative;
        padding-bottom: 15px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
        }

        @include media(XL) {
            padding-bottom: 0;
            overflow-x: visible;
        }
    }

    &__List {
        display: flex;
        justify-content: flex-start;
        margin-top: -8px;
        border-bottom: 2px solid $grey-30;

        @include media(XL) {
            margin-top: 0;
        }
    }

    &__Item {
        @include textstyle(body);

        flex: 0 0 auto;
        position: relative;
        padding: 14px 12px;
        margin-top: 8px;
        margin-bottom: -2px;
        color: $grey;
        font-size: 1.4rem;
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none;
        transition: color $transition;
        outline: none;
        cursor: pointer;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 2px;
            background: $grey-30;
            transition: background $transition;
            pointer-events: none;
        }

        @include media(L) {
            margin-top: 0;
            padding: 16px 20px;
            font-size: 1.6rem;
        }

        &--Empty,
        &--Loading {
            color: $grey-32;
        }

        // Placeholder for creating space when list is overflow
        &--Placeholder {
            width: 40px;

            @include media(XL) {
                display: none;
            }
        }

        &--Active {
            color: black;
            font-weight: $heavy;
            cursor: default;

            &::after {
                background: black;
            }

            &:hover {
                &::after {
                    background: $purple;
                }
            }
        }

        &:not(#{$root}__Item--Active):not(#{$root}__Item--Empty) {
            &:hover,
            &:focus {
                &::after {
                    background: $purple;
                }
            }
        }
    }

    &__Count {
        #{$root}__Item--Loading & {
            display: none;
        }
    }
}
