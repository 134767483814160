/* stylelint-disable no-descending-specificity */
@import 'Styles/includes';

.GalleryImage {
    $root: &;

    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 68.6%;
    cursor: pointer;

    &__Image {
        #{$root} & {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__Overlay {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(black, .24);
        opacity: 0;
        transition: opacity $transition;

        svg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 25px;
            height: 26px;
            margin: auto;
            fill: $purple-40;

            :global(.Theme--Arkitekturgalan) & {
                fill: $arkitekturgalan-primary;
            }
        }

        #{$root}:hover & {
            opacity: 1;
        }
    }

    &__Expand {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background: $purple-10;

        :global(.Theme--Arkitekturgalan) & {
            background: $arkitekturgalan-secondary;
        }

        @include media(M) {
            width: 54px;
            height: 54px;
        }

        svg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 24px;
            height: 24px;
            margin: auto;
            fill: $purple-50;

            :global(.Theme--Arkitekturgalan) & {
                fill: $arkitekturgalan-primary;
            }
        }
    }
}

/* stylelint-enable no-descending-specificity */
