@use 'sass:math';

%grid {
    &__list {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + #{$gutter});
        margin-left: -$gutter;
        margin-bottom: -$gutter;

        @include media(M) {
            width: calc(100% + #{$gutterM});
            margin-left: -$gutterM;
            margin-bottom: -$gutterM;
        }
    }

    &__item {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        margin-left: $gutter;
        margin-bottom: $gutter;
        width: calc(100% - #{$gutter});

        @include media(M) {
            margin-left: $gutterM;
            margin-bottom: $gutterM;
            width: calc(100% - #{$gutterM});
        }

        &--half {
            @include media(M) {
                width: calc(50% - #{$gutterM});
            }
        }

        &--third {
            @include media(M) {
                width: calc(#{percentage(math.div(1, 3))} - #{$gutterM});
            }
        }

        &--fourth {
            @include media(M) {
                width: calc(50% - #{$gutterM});
            }
            @include media(L) {
                width: calc(25% - #{$gutterM});
            }
        }
    }
}