@import 'Styles/includes';

.SearchTags {
    $root: &;

    &__Label {
        @include textstyle(body-small);
    }

    &__List {
        margin-left: -8px;

        @include media(M) {
            margin-left: -16px;
        }
    }

    &__Item {
        display: inline-block;
        position: relative;
        margin-top: 8px - 2px;
        margin-left: 8px;

        @include media(M) {
            margin-top: 16px - 2px;
            margin-left: 16px;
        }

        label {
            @include button(filter);
        }

        &--Active {
            label {
                @include button(filter active);
            }
        }

        input:focus + label {
            @include button(filter focus);
        }
    }

    &__Input {
        @extend %form__input--checkbox;
    }
}
