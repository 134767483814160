@import 'Styles/includes';

.InfoArchitectOffice {
    $root: &;

    width: 100%;
    padding: 24px;
    background-color: white;

    &__Title {
        @include textstyle(label);

        margin-bottom: 24px;
    }

    &__Logo {
        margin-bottom: 20px;
        display: block;
        max-width: 200px;
        position: relative;
    }

    &__SubTitle {
        @include textstyle(h3);

        margin-bottom: 16px;
    }
}
