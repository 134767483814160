@import 'Styles/includes';

.GallerySlider {
    $root: &;

    &__Slide {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;

        &--Single {
            justify-content: flex-start;
        }

        &--Multiple {
            justify-content: flex-end;
        }

        &--Center {
            justify-content: center;
        }
    }

    &__Item {
        position: relative;
        transition: transform .6s ease-in-out;

        #{$root}__Slide--Multiple &:not(:first-child) {
            margin-top: 16px;

            @include media(SL) {
                margin-top: 24px;
            }

            @include media(ML) {
                margin-top: 40px;
            }

            @include media(L) {
                margin-top: 80px;
            }
        }

        #{$root}__Slide--Single & {
            transform-origin: top center;
        }

        #{$root}__Slide--Multiple & {
            transform-origin: bottom center;
        }

        #{$root}__Slide--Active & {
            transform: scale(1.06);
            z-index: 1;

            @include media(M) {
                transform: none;
                z-index: auto;
            }
        }
    }

    &__Placeholder {
        #{$root}__Item--Loaded & {
            display: none;
        }
    }

    &__Button {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        border: none;
        border-radius: 0;
        background: transparent;
        box-shadow: none;
        -webkit-appearance: none;
        opacity: 0;
        transition: opacity 1s ease-in-out;
        overflow: hidden;
        cursor: pointer;

        #{$root}__Item--Loaded & {
            position: relative;
            opacity: 1;
        }

        svg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 25px;
            height: 26px;
            margin: auto;
            fill: $purple-40;
            opacity: 0;
            transition: opacity $transition;
            z-index: 1;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(black, .24);
            opacity: 0;
            transition: opacity $transition;
            pointer-events: none;
            z-index: 1;
        }

        &:hover {
            &::before,
            svg {
                opacity: 1;

                @media (hover: none) {
                    opacity: 0;
                }
            }
        }
    }

    &__Image {
        position: relative;
        display: block;
        transition: transform $transition;

        #{$root}__Button:hover & {
            transform: scale(1.1);

            @media (hover: none) {
                transform: none !important;
            }
        }
    }

    &__Modal {
        position: relative;
        z-index: 2;
    }
}
