@import 'Styles/includes';

.Modules {
    $root: &;

    @media print {
        display: none;
    }

    &__Item {
        position: relative;

        /* Specifically used for parallax with middle shape */
        &--FAQModule {
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: white;
                z-index: -1;
            }
        }

        &--Snippet {
            background: white;
        }
    }
}
