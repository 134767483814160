@import 'Styles/includes';

.SearchList {
    $root: &;

    &__Loader {
        @extend %loader--large;

        margin: 200px auto 400px;

        @include media(M) {
            margin-bottom: 600px;
        }
    }

    &__NoResult,
    &__Error {
        min-height: 300px;
    }

    &__List {
        @extend %grid__list;
    }

    &__Item {
        @extend %grid__item;
        @extend %grid__item--third;

        display: flex;
        flex-direction: column;
    }

    &__Footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 24px;

        @include media(L) {
            margin-top: 32px;
            height: 56px;
        }
    }

    &__LoadMore {
        @include button(primary exclude-icon);

        text-align: center;
        min-width: 0;
    }

    &__Button {
        @include button(primary);

        align-self: flex-end;
        margin-top: 16px;

        @include media(L) {
            position: absolute;
            top: 0;
            right: 0;
            min-width: 282px;
            margin: 0;
        }
    }
}
