@use 'sass:math';

@import 'Styles/includes';

.Diagram {
    $root: &;

    background-color: white;

    @include media(XXL) {
        padding: 0 24px;
    }

    @media print {
        display: block !important;
        opacity: 1 !important;
    }

    &--InTab {
        margin: 0;

        @include media(M) {
            margin: 0;
        }
    }

    &__Header {
        padding: 16px 0;

        @include media(XXL) {
            padding: 16px;
        }
    }

    &__Title {
        @include textstyle(h3);

        margin: 0 0 16px !important;
    }

    &__Dropdown {
        @extend %form__field;
        @extend %form__field--select;

        margin-top: 32px;
        margin-bottom: 8px;
        max-width: 400px;

        @media print {
            display: none;
        }
    }

    &__Select {
        @extend %form__input;
        @extend %form__input--select;
    }

    &__Content {
        width: 100%;

        &:not(:empty) {
            min-height: 400px;
        }

        @media print {
            width: 460px;
            display: block !important;
        }
    }

    &__Group {
        @media print {
            break-inside: avoid;
        }
    }

    &__Group-Title {
        @include textstyle(h4);

        padding: 16px 0;
        margin: 0 !important;
        color: black;
        max-width: 600px;

        @include media(XXL) {
            padding: 16px;
        }

        @media print {
            padding: 0;
        }
    }

    &__Group-Container {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
    }

    &__Item {
        width: 100%;

        #{$root}--Pie & {
            text-align: center;

            @include media(M) {
                max-width: 70%;
            }

            @media print {
                max-width: none;
            }
        }

        #{$root}__Group--Third & {
            @include media(M) {
                width: percentage(math.div(1, 3));
            }

            @include media(L) {
                width: 100%;
            }

            @include media(XL) {
                width: #{percentage(math.div(1, 3))};
            }
        }

        #{$root}__Group--Half & {
            @include media(M) {
                width: 50%;
            }
        }
    }

    &__ItemTitle {
        @include textstyle(h5);

        margin: 16px 0 0 !important;
        padding: 8px 0;

        @include media(XXL) {
            padding: 8px 16px;
        }

        @media print {
            margin: 0;
            padding: 0;
        }
    }

    &__Description {
        @include textstyle(body-small);

        max-width: 530px;
        margin: 8px 24px;
        color: $base-color;

        @include media(M) {
            margin: 16px 32px 24px;
        }

        @include media(XXL) {
            margin: 16px 40px 24px;
        }
    }

    &__Placeholder {
        position: relative;

        &::before {
            content: '';
            display: block;
            padding-top: #{percentage(math.div(9, 16))};
        }
    }
}
