/*
    Following styling only works if labels are placed directly after inputs to be able to style label depending on focus

    e.g. Checkbox markup
    <form class="form">
        <div class="form__field-wrapper form__field-wrapper--checkbox">
            <div class="form__field form__field--checkbox">
                <input type="checkbox" class="form__input form__input--checkbox">
                <label class="form__label form__label--checkbox">Checkbox</label>
            </div>
        </div>
    </form>

    For validation, either append class for when a field has changed and extend .form__input--changed,
    this will handle if the field is valid by browser or not,
    or extend .form__input--success and .form__input--error directly

    Also append a span after the input which should extend .form__field--success-icon for
    applying a success icon to fields on validation
*/

%form {
    $root: &;

    &__field-wrapper {
        position: relative;
        margin: 24px 0;
    }

    // For appending success icon to regular fields, not selects, radios or checkboxes
    &__success-icon,
    &__error-icon {
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 14px;
            right: 12px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            z-index: $z-Form;

            @include media(M) {
                top: 18px;
                right: 16px;
            }
        }

        &--checkbox {
            &::before {
                top: 12px !important;
                right: 0 !important;
            }
        }
    }

    &__error-icon {
        &::before {
            background-image: url(#{$assetsPath}img/error--red.svg);
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    &__error-bg {
        background-image: url(#{$assetsPath}img/error--red.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 12px) 13px;
    }

    &__field {
        position: relative;

        &--select {
            &::before {
                content: '';
                width: 56px;
                display: block;
                position: absolute;
                top: 1px;
                bottom: 1px;
                right: 1px;
                background: white;
                pointer-events: none;
            }

            &::after {
                content: '';
                height: 6px;
                width: 11px;
                position: absolute;
                bottom: 21px;
                right: 24px;
                background: url(#{$assetsPath}img/dropdown--grey40.svg) no-repeat center center;
                background-size: contain;
                pointer-events: none;

                @include media(M) {
                    bottom: 25px;
                }
            }
        }

        &--search {
            &::after {
                content: '';
                width: 19px;
                height: 20px;
                position: absolute;
                bottom: 16px;
                right: 22px;
                background: url(#{$assetsPath}img/search--grey40.svg) no-repeat center center;
                background-size: contain;
                pointer-events: none;

                @include media(M) {
                    bottom: 18px;
                }
            }
        }
    }

    &__input {
        display: block;
        position: relative;
        width: 100%;
        padding: 12px 16px;
        color: $grey-70;
        font-size: 1.6rem;
        font-family: $base-font;
        font-weight: $regular;
        line-height: 1.4;
        border: 1px solid $grey-30;
        border-radius: 0px;
        background: white;
        outline: none;
        transition: border-color $transition, background-color $transition, color $transition;
        background-clip: padding-box;
        box-shadow: none;

        @include media(M) {
            padding: 16px 24px;
        }

        /* Separating modifiers and state-selectors decreases rows in compiled css */
        &--hover {
            border-color: $grey-35;
        }

        &--focus {
            border-color: $grey-35;
            border-bottom-color: $primary-color !important;

            :global(.Theme--Arkitekturgalan) & {
                border-bottom-color: $arkitekturgalan-primary !important;
            }
        }

        &--disabled {
            border: $grey-10;
            background: $grey-10;
        }

        &:not(:disabled):hover {
            @extend %form__input--hover;
        }

        &:not(:disabled):focus {
            @extend %form__input--focus;
        }

        &:disabled {
            @extend %form__input--disabled;
        }

        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 black;
        }

        // Removing yellow background on fields when autofill is active in Chrome
        &:-webkit-autofill {
            -webkit-animation-name: RemoveAutofill;
            -webkit-animation-fill-mode: both;
        }

        // Remove close icon in IE
        &::-ms-clear {
            display: none;
        }

        &::placeholder {
            color: $grey;
            line-height: 1.4;
            font-weight: $regular;
            font-family: $base-font;
        }

        &--label-inside { // 23
            padding-top: 18px;
            padding-bottom: 10px;

            @include media(M) { // 32
                padding-top: 20px;
                padding-bottom: 12px;
            }

            &:-webkit-autofill {
                & + label {
                    /*
                     * Must be separated into own style without extend,
                     * otherwise it will break focus styling completely in firefox
                     * This is taken from %form__label--inside-focus
                     */
                    top: 0;
                    letter-spacing: .3px;
                    font-size: 1.2rem;
                    font-weight: $regular;
                    padding-top: 4px;

                    @include media(M) {
                        padding-top: 6px;
                        font-size: 1.2rem;
                    }

                    transition: none;
                }
            }
        }

        &--success,
        &--changed:valid {
            padding-right: 60px;
            border-bottom: 1px solid $success-color;

            ~ #{$root}__SuccessIcon {
                opacity: 1;
            }
        }

        &--error,
        &--changed:not(:valid) {
            padding-right: 40px;
            color: $grey;
            border-bottom: 1px solid $error-color;
        }

        &--checkbox,
        &--radio {
            width: 0;
            height: 0;
            margin: 0;
            opacity: 0;
            position: absolute;
        }

        &--select {
            padding: 12px 60px 12px 16px;
            color: $grey;
            text-overflow: '';
            text-indent: 0.01px;
            -webkit-appearance: none;
            -moz-appearance: none;

            &::-ms-expand {
                display: none;
            }

            &--focus {
                border-color: $primary-color;

                :global(.Theme--Arkitekturgalan) & {
                    border-color: $arkitekturgalan-primary;
                }
            }

            &:not(:disabled):focus {
                @extend %form__input--select--focus;
            }

            @include media(M) {
                padding: 16px 60px 16px 24px;
            }
        }

        &--select-arrow {
            background: white url(#{$assetsPath}img/dropdown--grey40.svg) no-repeat !important;
            background-position: calc(100% - 24px) center !important;
            background-size: 11px 6px !important;
        }

        &--multi-select {
            margin-top: 5px;
            padding: 10px 5px;
        }

        &--react-select { // Make sure to inlude --select also
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 10px;

            @include media(M) {
                padding-top: 12px;
                padding-bottom: 12px;
                padding-left: 10px;
            }
        }

        &--file {
            button[type=button] {
                @include button(tertiary);

                margin-right: 16px;
            }
        }

        &--multi-file {
            padding-top: 24px;
            padding-bottom: 24px;
            text-align: center;
            border-style: dashed;
            cursor: pointer;
            transition: background $transition;

            @include media(M) {
                padding-top: 32px;
                padding-bottom: 32px;
            }

            &--focus {
                border-bottom-color: $grey-35 !important;
                background: $purple-20;

                :global(.Theme--Arkitekturgalan) & {
                    background: $arkitekturgalan-secondary;
                }
            }

            &--hover {
                background: $purple-10;

                :global(.Theme--Arkitekturgalan) & {
                    background: $arkitekturgalan-secondary;
                }
            }

            &:not(:disabled):focus {
                @extend %form__input--multi-file--focus;
            }

            &:not(:disabled):hover {
                @extend %form__input--multi-file--hover;
            }
        }

        &--search {
            padding: 14px 48px 14px 16px;

            @include media(M) {
                padding: 16px 60px 16px 24px;
            }
        }
    }

    &__label {
        position: relative;
        display: block;
        margin-bottom: 16px;
        color: black;
        letter-spacing: .2px;
        font-size: 1.4rem;
        font-weight: $medium;
        font-family: $base-font;
        line-height: 1.4;
        transition: all $transition;

        @include media(M) {
            font-size: 1.6rem;
        }

        a {
            color: $grey;
            text-decoration: underline;
            transition: color $transition;

            &:hover {
                color: $purple;
            }

            :global(.Theme--Arkitekturgalan) & {
                color: $arkitekturgalan-link;

                &:hover {
                    color: $arkitekturgalan-link-hover;
                }
            }
        }

        &--inside {
            position: absolute;
            top: 14px;
            left: 16px;
            right: 16px;
            margin: 0;
            color: $grey;
            font-size: 1.6rem;
            font-weight: $regular;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            z-index: $z-Form;

            @include media(M) {
                top: 15px;
                left: 24px;
                right: 24px;
                font-size: 1.8rem;
            }

            &-focus {
                top: 0;
                letter-spacing: .3px;
                font-size: 1.2rem;
                font-weight: $regular;
                padding-top: 4px;

                @include media(M) {
                    padding-top: 6px;
                    font-size: 1.2rem;
                }
            }

            input:focus + &,
            textarea:focus + &,
            select:focus + & {
                @extend %form__label--inside-focus;
            }

            &-error {
                padding-right: 20px;
            }
        }

        &--search {
            right: 48px;

            @include media(M) {
                right: 60px;
            }
        }

        &--group {
            margin-bottom: 8px;
        }

        &--checkbox,
        &--radio {
            padding: 9px 24px 8px 48px;
            padding-left: 48px;
            margin-bottom: 8px;
            margin-left: -11px;
            color: $grey;
            font-size: 1.4rem;
            font-weight: $regular;
            line-height: 1.7;

            &:hover {
                &::before {
                    background: $purple-10;

                    :global(.Theme--Arkitekturgalan) & {
                        background: $arkitekturgalan-secondary;
                    }
                }
            }

            &-focus {
                &::before {
                    background: $purple-20;

                    :global(.Theme--Arkitekturgalan) & {
                        background: $arkitekturgalan-secondary;
                    }
                }
            }

            &:active,
            &:focus {
                @extend %form__label--checkbox-focus;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: transparent;
                transition: background $transition;
            }

            &::after {
                content: '';
                position: absolute;
                top: 11px;
                left: 11px;
                display: block;
                width: 17px;
                height: 17px;
                border: 1px solid $primary-color;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                transition: background $transition;

                :global(.Theme--Arkitekturgalan) & {
                    border-color: $arkitekturgalan-primary;
                }
            }

            &-checked {
                &::after {
                    background-image: url('#{$assetsPath}img/checkbox--checked.svg');

                    :global(.Theme--Arkitekturgalan) & {
                        background-image: url('#{$assetsPath}img/checkbox--checked-arkitekturgalan.svg');
                    }
                }
            }
        }

        &--radio {
            &::after {
                border-radius: 50%;
                border: 1px solid $primary-color;

                :global(.Theme--Arkitekturgalan) & {
                    border-color: $arkitekturgalan-primary;
                }
            }

            &-checked {
                &::after {
                    background-image: url('#{$assetsPath}img/radio--checked.svg');

                    :global(.Theme--Arkitekturgalan) & {
                        background-image: url('#{$assetsPath}img/radio--checked-arkitekturgalan.svg');
                    }
                }
            }
        }
    }

    &__option {
        padding: 4px 20px;
        color: $grey;
        font-size: 1.6rem;
        font-family: $base-font;
        font-weight: $bold;

        &:checked {
            background: $grey-10;
        }
    }

    &__react-select {
        @extend %form__field;

        &__control {
            #{$root}__react-select & {
                @extend %form__input;

                padding: 0 !important;

                &--is-focused {
                    @extend %form__input--focus;
                }
            }
        }

        &__value-container {
            #{$root}__react-select & {
                @extend %form__input--select;
                @extend %form__input--react-select;

                padding-right: 72px;
            }
        }

        &__placeholder {
            #{$root}__react-select & {
                margin: 0;
                padding-left: 16px;
            }
        }

        &__single-value {
            #{$root}__react-select & {
                padding-left: 16px;
                padding-right: 68px;
            }
        }

        &__multi-value {
            #{$root}__react-select & {
                color: $grey;
                border: 1px solid $grey-30;
                border-radius: 0;
                background: white;
                transition: border-color $transition;

                &:hover {
                    border-color: $grey-35;
                }

                &__label {
                    color: $grey;
                }

                &__remove {
                    border-radius: 0px;
                    background: transparent;
                    transition: color $transition;
                    cursor: pointer;

                    &:hover {
                        color: $purple;
                        background: transparent;

                        :global(.Theme--Arkitekturgalan) & {
                            color: $arkitekturgalan-primary;
                        }
                    }
                }
            }
        }

        &__input {
            #{$root}__react-select & {
                margin-left: 14px;

                &--is-multi-has-value {
                    margin-left: 4px;
                }
            }
        }

        &__value-container--is-multi#{$root}__react-select__value__container--has-value #{$root}__react-select__input {
            #{$root}__react-select & {
                @extend %form__react-select__input--is-multi-has-value;
            }
        }

        &__indicators {
            #{$root}__react-select & {
                position: absolute;
                top: 9px;
                right: 18px;
            }
        }

        &__indicator {
            padding: 8px 6px;
            height: 36px;
        }

        &__indicator-separator {
            display: none;
        }

        &__clear-indicator {
            cursor: pointer;

            svg {
                fill: $grey-40;
                transition: fill $transition;
            }

            &:hover {
                svg {
                    fill: $purple;

                    :global(.Theme--Arkitekturgalan) & {
                        fill: $arkitekturgalan-primary;
                    }
                }
            }
        }

        &__dropdown-indicator {
            #{$root}__react-select & {
                position: relative;

                svg {
                    display: none;
                }

                &::after {
                    content: '';
                    height: 6px;
                    width: 11px;
                    position: relative;
                    top: 7px;
                    right: 0;
                    background: url(#{$assetsPath}img/dropdown--grey40.svg) no-repeat center center;
                    background-size: contain;
                    pointer-events: none;
                }
            }
        }

        &__menu {
            #{$root}__react-select & {
                margin: -1px 0 0 0;
                border-radius: 0px;
                border: 1px solid $grey-35;
                border-top-color: $primary-color;
                box-shadow: none;
                z-index: 2;

                :global(.Theme--Arkitekturgalan) & {
                    border-top-color: $arkitekturgalan-primary;
                }
            }
        }

        &__option {
            #{$root}__react-select & {
                padding: 10px 12px;
                text-overflow: ellipsis;
                overflow: hidden;

                &--is-focused {
                    color: $grey;
                    background: $purple-10;

                    :global(.Theme--Arkitekturgalan) & {
                        background: $arkitekturgalan-secondary;
                    }
                }

                &--is-selected {
                    background: $purple;

                    :global(.Theme--Arkitekturgalan) & {
                        background: $arkitekturgalan-primary;
                    }
                }

                // Styling for dropdowns where there is a hierarchy
                > strong {
                    font-weight: $bold;

                    // Indent only when a root item is placed before
                    ~ span {
                        padding-left: 20px;
                        line-height: 1.2;
                        display: inline-block;
                    }
                }
            }
        }
    }

    &__message {
        @include textstyle(helptext);

        font-family: $base-font;
        padding: 3px 16px;

        @include media(M) {
            padding: 3px 24px;
        }

        &--checkbox {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__error {
        @extend %form__error-icon;

        @include textstyle(body-small);

        position: relative;
        margin: 24px 0;
        padding: 15px 16px 15px 40px;
        font-family: $base-font;
        border: 1px solid $error-color;
        background: white;

        @include media(M) {
            padding: 17px 24px 17px 48px;
        }

        a {
            color: $primary-color;

            &:hover {
                text-decoration: underline;
            }

            :global(.Theme--Arkitekturgalan) & {
                color: $arkitekturgalan-link;

                &:hover {
                    color: $arkitekturgalan-link-hover;
                }
            }
        }

        &::before {
            left: 12px;
            right: auto;
            bottom: 14px;
            margin: auto;

            @include media(M) {
                left: 16px;
            }
        }
    }

    &__submit {
        @include button(primary);

        &--disabled {
            @include button(primary disabled);
        }

        &--input {
            @include button(primary exclude-icon);
        }
    }

    &__reset {
        position: relative;
        padding: 8px 8px 8px 20px;
        color: $base-color;
        font-size: 1.4rem;
        line-height: 1.4;
        cursor: pointer;
        outline: none;
        transition: color $transition;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 9px;
            left: 20px;
            right: 8px;
            height: 1px;
            background-color: $base-color;
            transition: background-color $transition;
        }

        svg {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 12px;
            height: 12px;
            margin: auto;
            fill: $base-color;
            transition: fill $transition;
        }

        &:hover,
        &:focus {
            color: $primary-color;

            :global(.Theme--Arkitekturgalan) & {
                color: $arkitekturgalan-primary;
            }

            &::after {
                background-color: $primary-color;

                :global(.Theme--Arkitekturgalan) & {
                    background-color: $arkitekturgalan-primary;
                }
            }

            svg {
                fill: $primary-color;

                :global(.Theme--Arkitekturgalan) & {
                    fill: $arkitekturgalan-primary;
                }
            }
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: rgba(white, .7);
        z-index: $z-Form;
    }

    &__loader {
        @extend %loader--large;
    }
}
