@use 'sass:math';

@import 'Styles/includes';

.Competition {
    $root: &;

    &__Wrap {
        @include wrap;
    }

    &__Container {
        display: grid;
        gap: 24px;
        margin: 0 0 24px 0;

        @include media(M) {
            grid-template-columns: repeat(2, 1fr);
            grid-auto-flow: dense;
            gap: 0;
        }
    }

    &__Image {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: math.div(458px, 631px) * 100%;
        background-color: $grey-20;

        &--Sidebar {
            display: block;
        }

        &--Top {
            display: none;
            grid-column: 1 / -1;
        }

        #{$root}--HasImage#{$root}--Finished & {
            padding-top: math.div(620px, 1260px) * 100%;

            &--Top {
                @include media(M) {
                    display: block;
                }
            }

            &--Sidebar {
                @include media(M) {
                    display: none;
                }
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__Badge {
        @include textstyle(label);
        padding: 21px 21px 0 48px;
        color: black;
        background-color: $salmon;
        position: absolute;
        top: 0;
        right: 0;

        &::after {
            content: '';
            width: 100%;
            height: 0;
            position: absolute;
            top: 100%;
            left: 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-bottom: 71px solid transparent;
            border-right: 132px solid $salmon;
        }
    }

    &__Aside {
        max-width: calc(100% - 32px);
        margin: 0 auto;
        position: relative;

        @include media(L) {
            max-width: 420px;
        }
    }

    &__InfoCompetition {
        margin-bottom: $gutter;

        @include media(M) {
            margin-bottom: $gutterM;
        }

        #{$root}--HasImage & {
            margin-top: -50px !important;
        }
    }

    &__Content {
        width: 100%;
        margin: 24px 0 0 0;
        font-size: 2rem;
        letter-spacing: 0.02rem;
        line-height: 1.4;

        @include media(M) {
            margin: 0;
            padding-right: 52px;
        }

        @include media(L) {
            width: 100%;
            max-width: 576px + 69px;
        }

        #{$root}--HasImage & {
            margin-top: 40px;

            @include media(M) {
                margin-top: 64px;
            }

            @include media(L) {
                margin-top: 100px;
            }
        }

        #{$root}--HasImage#{$root}--Finished & {
            margin-top: 16px;

            @include media(M) {
                margin-top: 24px;
            }

            @include media(L) {
                margin-top: 60px;
            }
        }
    }

    &__Label {
        @include textstyle(label);
        color: $purple;
    }

    &__Title {
        @include textstyle(h1);
        margin: 0 0 24px 0;
    }

    &__Meta {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 22px;
    }

    &__Published {
        @include textstyle(body-small);

        margin-right: 20px;
    }

    &__Modified {
        @include textstyle(body-small);
    }

    &__LinkList {
        margin-bottom: 24px;
    }

    &__Fixed {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 9;

        @include media(ML) {
            display: none;
        }
    }

    &__Sticky {
        display: none;

        @include media(ML) {
            @include wrap;
            position: sticky;
            top: calc(100% - 65px);
            right: 0;
            left: 0;
            z-index: 9;
            display: block;
            opacity: 0;
            transform: translateY(65px);
            transition: transform $transition, opacity $transition;
        }

        &--IsVisible {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &__ShareLinks {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;

        @include media(M) {
            margin-top: 0;
        }
    }

    &__Modules {
        overflow: hidden;
    }
}
