@import 'Styles/includes';

.Course {
    $root: &;

    &__InfoCourse {
        margin-bottom: 24px;
    }

    &__ShareLinks {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;

        @include media(M) {
            margin-top: 0;
        }
    }
}
