@import 'Styles/includes';

.InfoLandscapeArchitect {
    $root: &;

    width: 100%;
    padding: 24px;
    background-color: white;

    &__Title {
        @include textstyle(label);

        margin-bottom: 24px;
    }

    &__List {
    }
}
