@use 'sass:math';

@import 'Styles/includes';

.Embed {
    $root: &;

    position: relative;

    &__Title {
        // For making sure it overrides richtext style
        #{$root} & {
            @include textstyle(h4);

            margin: 0;
        }

        position: absolute;
        top: 0;
        left: 0;
        max-width: 365px;
        padding: 17px 40px 23px 32px;
        z-index: 1;

        span {
            position: relative;
            z-index: 1;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% - 30px);
            background: rgba(white, .8);
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: calc(100% - 60px);
            height: 0;
            border-right: 60px solid transparent;
            border-top: 30px solid rgba(white, .8);
        }

        #{$root}--ShowVideo & {
            display: none;
        }
    }

    &__Block {
        position: relative;
        margin: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100% !important;
            height: 100% !important;
        }
    }

    &__Video {
        position: relative;

        &::before {
            content: '';
            display: block;
            padding-top: #{percentage(math.div(9, 16))};

            #{$root}--Embed-aspect-21-9 & {
                padding-top: #{percentage(math.div(21, 9))};
            }
            #{$root}--Embed-aspect-18-9 & {
                padding-top: #{percentage(math.div(9, 18))};
            }
            #{$root}--Embed-aspect-16-9 & {
                padding-top: #{percentage(math.div(9, 16))};
            }
            #{$root}--Embed-aspect-4-3 & {
                padding-top: #{percentage(math.div(3, 4))};
            }
            #{$root}--Embed-aspect-1-1 & {
                padding-top: 100%;
            }
            #{$root}--Embed-aspect-9-16 & {
                padding-top: #{percentage(math.div(16, 9))};
            }
            #{$root}--Embed-aspect-1-2 & {
                padding-top: 200%;
            }
        }
    }

    &__LazyLoad {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    &__Player {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity $transition;

        #{$root}--ShowVideo & {
            opacity: 1;
        }

        iframe {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

    &__Figcaption {
        @include textstyle(helptext);

        margin-top: 12px;
    }

    &__Button {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
        background: none;
        -webkit-appearance: none;
        cursor: pointer;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 40px;
            height: 40px;
            margin: auto;
            background: $primary-color url('#{$assetsPath}img/play--white.svg') no-repeat center center;
            background-size: 14px;
            transition: background-color $transition;

            :global(.Theme--Arkitekturgalan) & {
                background-color: $arkitekturgalan-button-primary;
            }
        }

        &:hover {
            &::after {
                background-color: $purple-60;

                :global(.Theme--Arkitekturgalan) & {
                    background-color: $arkitekturgalan-button-primary-hover;
                }
            }
        }
    }

    &__Message {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
