@import 'Styles/includes';

.SearchFilter {
    $root: &;

    &__Form {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-left: -$gutter;

        @include media(M) {
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: -$gutterM;
            margin-left: -32px;
        }

        #{$root}--Global & {
            flex-direction: row;
        }
    }

    &__Top {
        flex: 0 0 auto;
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - #{$gutter});
        margin-left: $gutter;

        @include media(M) {
            width: calc(100% - 32px);
            padding-right: calc(170px + #{$gutterM});
            margin-left: 32px;
            margin-bottom: 2px;
        }

        #{$root}--Global & {
            @include media-max(M) {
                flex: 1 1 auto;
                width: auto;
            }
        }
    }

    &__Field {
        flex: 1 0 auto;
        width: 100%;
        max-width: 300px;
        margin-bottom: $gutter;

        @include media(M) {
            width: calc(50% - #{$gutterM});
            max-width: none;
            margin-right: $gutterM;
            margin-bottom: 0;
        }

        #{$root}--Global & {
            margin-bottom: 0;
        }
    }

    &__FirstFilter {
        flex: 0 0 auto;
        width: 100%;
        max-width: 300px;
        margin-bottom: $gutter;

        @include media(M) {
            width: calc(50% - #{$gutterM});
            max-width: none;
            margin-right: $gutterM;
            margin-bottom: 0;
        }
    }

    &__Selects {
        flex: 0 0 auto;
        width: calc(100% - $gutter);
        display: flex;
        flex-wrap: wrap;
        margin-left: $gutter;

        @include media(M) {
            width: calc(100% - 32px);
            padding-right: calc(170px + #{$gutterM});
            margin-top: 22px;
            margin-left: 32px;
        }
    }

    &__SelectItem {
        flex: 0 0 auto;
        width: 100%;
        max-width: 300px;
        margin-bottom: $gutter;

        @include media(M) {
            width: calc(50% - #{$gutterM});
            max-width: none;
            margin-right: $gutterM;
            margin-bottom: 0;
        }
    }

    &__Tags {
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-left: $gutter;

        @include media(M) {
            margin-left: 32px - $gutterM;
        }
    }

    &__TagItem {
        width: 100%;
        margin-bottom: $gutter;

        @include media(M) {
            width: auto;
            margin-top: #{$gutterM - 2px};
            margin-left: $gutterM;
            margin-bottom: 0;
        }
    }

    &__Submit {
        @extend %form__submit;

        width: 170px;
        min-width: 0;
        margin-left: $gutter;

        @include media(M) {
            position: absolute;
            top: 0;
            right: $gutterM;
            width: 170px;
            margin-top: 0;
        }

        #{$root}--Global & {
            @include media-max(M) {
                width: auto;
                flex: 1 0 auto;
                padding-top: 16px;
                padding-bottom: 17px;
            }
        }
    }

    &__Reset {
        @extend %form__reset;

        flex: 0 0 auto;
        align-self: flex-end;

        #{$root}--Global & {
            position: absolute;
            top: 54px;
            right: -8px;
        }

        &,
        #{$root}--Global & {
            @include media(M) {
                position: absolute;
                top: 64px;
                right: 16px;
            }
        }
    }
}
