@import 'Styles/includes';

.CardMemberCompany {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 32px 0 rgba(black, .12);
        opacity: 0;
        transition: opacity $transition;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    &__Logo {
        margin-bottom: 20px;

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 150px;
        }
    }

    &__Content {
        position: relative;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        padding: 20px 24px 32px;
        background: white;

        #{$root}--NoImage & {
            justify-content: center;
            padding-bottom: 20px;
        }
    }

    &__Label {
        @include textstyle(label);

        margin-bottom: 11px;
        color: $purple;
        word-break: break-word;
    }

    &__TitleLink {
        color: black;

        &:hover {
            color: $purple;
            text-decoration: underline;
        }
    }

    &__Title {
        @include textstyle(h3);

        word-break: break-word;

        #{$root}__TitleLink & {
            color: inherit;
        }
    }

    &__Text {
        @include textstyle(card-text);

        margin-top: 12px;
        color: $grey;
    }

    &__Link {
        @include textstyle(body-small);

        flex: 0 1 auto;
        color: $purple;
        margin-top: 10px;
        word-break: break-word;

        svg {
            display: inline-block;
            position: relative;
            top: 2px;
            width: 18px;
            height: 13px;
            margin-right: 8px;
            fill: $purple;
            transition: fill $transition;
        }

        &--Phone {
            svg {
                top: 3px;
                height: 16px;
            }
        }

        &--Website {
            margin-bottom: 8px;

            svg {
                margin-right: 0;
                margin-left: 4px;
            }
        }

        :global(.Theme--Arkitekturgalan) & {
            color: $arkitekturgalan-link;

            svg {
                fill: $arkitekturgalan-link;
            }
        }

        &:hover {
            color: $grey !important;

            svg {
                fill: $grey !important;
            }
        }
    }

    &__LinkText {
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $purple;
            transition: background $transition;

            #{$root}__Link:hover & {
                background: $grey !important;
            }

            :global(.Theme--Arkitekturgalan) & {
                background: $arkitekturgalan-link;
            }
        }
    }

    &__Footer {
        display: flex;
        align-items: center;
        min-height: 57px;
        padding: 20px 24px;
        border-top: 1px solid $grey-20;
        background: white;
    }

    &__County {
        @include textstyle(label);

        position: relative;
        padding: 0 16px 0 24px;
        line-height: 1.4;

        &::before {
            content: '';
            position: absolute;
            top: 1px;
            left: 3px;
            width: 12px;
            height: 14px;
            display: inline-block;
            background: url('#{$assetsPath}img/pin--grey40.svg') no-repeat center center;
            background-size: contain;
        }
    }
}
