@import 'Styles/includes';

.EmbedMessage {
    $root: &;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(white, .8);

    &--Bottom {
        justify-content: flex-end;

        @include media(M) {
            justify-content: center;
        }
    }

    &__Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 8px;
        color: $base-color;
        font-size: 1.2rem;
        line-height: 1.4;
        text-align: center;

        @include media(S) {
            padding: 20px;
            font-size: 1.4rem;
        }

        @include media(M) {
            font-size: 1.8rem;
        }
    }

    &__Text {
        max-width: 550px;
        margin: 0;
        font-size: 1.2rem;

        a {
            color: $primary-color;
            padding-right: 15px;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            &::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
            }

            &::before,
            &::after {
                width: 10px;
                height: 10px;
                top: 2px;
            }
        }

        @include media(S) {
            font-size: 1.4rem;

            a {
                padding-right: 18px;

                &::before,
                &::after {
                    width: 12px;
                    height: 12px;
                    top: 3px;
                }
            }
        }

        @include media(M) {
            font-size: 1.6rem;

            a {
                padding-right: 20px;

                &::before,
                &::after {
                    width: 14px;
                    height: 14px;
                    top: 3px;
                }
            }
        }
    }

    &__Button {
        @include button(primary exclude-icon small);

        text-align: center;

        @include media-max(S) {
            padding: 4px 12px 5px !important;
            font-size: 1rem;
        }

        @include media(SL) {
            margin-top: 8px;
        }

        @include media(M) {
            margin-top: 24px;
        }
    }

    &__Link {
        @include button(link);

        color: $primary-color;
        text-decoration: none;
        border-color: transparent;

        @include media(SL) {
            margin-top: 8px;
        }

        @include media(M) {
            margin-top: 24px;
        }

        &:hover {
            border-color: $primary-color;
        }
    }
}
