@import 'Styles/includes';

.SearchResult {
    $root: &;

    &__Header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 24px;

        @include media(L) {
            margin-bottom: 40px;
        }
    }

    &__Title {
        @include textstyle(h4);

        padding-right: 8px;

        @include media(M) {
            margin-top: 8px;
            margin-bottom: 8px;
        }
    }
}
